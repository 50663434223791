@import "~/frontend/scss/stage0";

$primary: #1b7eff;
$secondary: #6c757d;
$alternative: #9f61ff;

@import "~/frontend/scss/stage1";

$color-contrast-original: get-function("color-contrast");
@function color-contrast(
  $background,
  $color-contrast-dark: $color-contrast-dark,
  $color-contrast-light: $color-contrast-light,
  $min-contrast-ratio: $min-contrast-ratio
) {
  @if $background == $primary {
    @return $color-contrast-light;
  }
  @return call(
    $color-contrast-original,
    $background,
    $color-contrast-dark,
    $color-contrast-light,
    $min-contrast-ratio
  );
}

@import "~/frontend/scss/stage2";

.btn-secondary {
  @include button-variant($alternative, $alternative, $white);
}
